.block
{
    &--multimedia__combo {


        &.block--multimedia__combo--featured_image,
        &.block--multimedia__combo--featured_video,
        &.block--multimedia__combo--slideshow,
        &.block--multimedia__combo--thumbnail_gallery {
            margin-top: 30px;
            margin-bottom: 30px;

            .block__title {
                margin-top: 0;
                margin-bottom: 15px;
                text-transform: capitalize;
                font-size: 2.0rem;

                @media #{$medium-up} {
                    margin: 30px 0 10px;
                    padding: 0;
                    text-transform: capitalize;
                    font-size: 2rem;
                    font-weight: 500;
                }
            }

            .cta-btn {
                margin-top: 15px;
            }
        }


        &.block--multimedia__combo--featured_image {
            .simple__image {
                float: right;
            }
        }

        &.block--multimedia__combo--featured_video {

        }

        &.block--multimedia__combo--slideshow {
            .slick-dots {
                margin-top: 5px;
                button {
                    height: 10px;
                    width: 10px;
                    padding: 0;
                    margin: 0;
                    &:before {
                        content: '';
                        height: 10px;
                        width: 10px;
                        background: $space-grey;
                    }
                }
            }
        }

        &.block--multimedia__combo--thumbnail_gallery {

        }

        .vertical-align-content {
            //@media #{$medium-up} {
                figure, .flex-video, .vertical-align-gallery, .vertical-align-slideshow {
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                }
            //}
        }
    }
}
