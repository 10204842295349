.acf-map {
  border: none;
  box-shadow: none;
  height: 320px;
  margin: 0;
  width: 100%;
}

.acf-map img {
  max-width: inherit !important;
}