.block {
    &__blog--list {

        .blog__item + .blog__item {
            margin-top: 20px;
        }

        .blog__item {
            max-width: 100%;
            width: 100%;
            position: relative;
            background: $grey;

            @media #{$large-up} {
                &:nth-child(even) {
                    .row {
                        & > .columns {
                            &:first-child { float: right; }
                            &:last-child { float: left; }
                        }
                    }
                }
                &:nth-child(odd) {
                    .row {
                        & > .columns {
                            &:first-child { float: left; }
                            &:last-child { float: right; }
                        }
                    }
                }
            }

            .blog__item--image {
                width: 100%;
                min-height: 400px;
                height: 100%;
                background-size: cover !important;
                background-position: 50% 50% !important;
                background-repeat: no-repeat !important;

                img {
                    position: absolute;
                    opacity: 0.2;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 60%;
                }
            }

            .blog__item--link {
                transition: .2s ease-in-out;
            }

            .blog__item--content {
                position: relative;
                overflow: hidden;
                padding: 30px 20px;
                height: 100%;
                &:hover {

                    img {
                        transform: scale(1.1);
                    }

                    .blog__item--image {
                        &:before{
                            opacity: 0.4;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }

                h3 {
                    text-align: left;
                    font-size: 2rem;
                    line-height: 2rem;
                    color: $black;
                    margin: 0;
                    text-transform: uppercase;
                    transition: .2s ease-in-out;
                }

                a {
                    &:hover {
                        h3 {
                            transition: .2s ease-in-out;
                            color: $blue;
                        }
                    }
                }

                p {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }

                .button.underline {
                    bottom: 0px;
                    left: 20px;
                    width: calc(100% - 40px);                }
            }
        }

        .blog-list__filter--container, .blog-list__search--container {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                background-size: contain !important;
                background-repeat: no-repeat !important;
            }

            .blog--list__search, .blog--list__filter {
                margin-bottom: 0;
                border: none;
                background: $light-grey;
                box-shadow: none;
                font-family: $font;
                text-transform: uppercase;
                color: $text-grey;
                font-size: 1.1rem;
                letter-spacing: .2rem;
                position: relative;
                padding-right: 45px;
                &:hover, &:focus {
                    outline: none;
                    cursor: pointer;
                }
            }
        }

        .blog-list__filter--container {
            &:after {
                height: 21px;
                width: 25px;
                right: 15px;
                top: 10px;
                background: url('../img/assets/category-icon.png');
            }

            @media #{$small-only} {
                margin-bottom: 20px;
            }
        }

    }
}