.block
{
    &.block--gallery {
        .block__title {
            margin: 30px 0 10px;
            padding: 0;
            text-transform: capitalize;
            font-size: 2.0rem;
        }

        .block--gallery__images {
            width: 100%;
            display: flex;

            .simple__image {
                margin: 6px;
                max-height: 400px;
                overflow: hidden;
                &:hover {

                }
            }


            .gallery__image + .gallery__image {
                margin-left: 10px;
            }

            &.js-photoswipe {
                .simple__image {
                    a {
                        position: relative;
                        display: block;

                        &:before, &:after {
                            position: absolute;
                            opacity: 0;
                            transition: opacity 500ms ease;
                            transform: translate3d(0,0,0);
                        }

                        &:before {
                            content: "";
                            top: 0;
                            left: 0;
                            background-color: rgba(black, 0.7);
                            width: 100%;
                            height: 100%;
                        }

                        &:after {
                            position: absolute;
                            content: "\f25e";
                            top: 50%;
                            left: 50%;
                            color: white;
                            font-size: 2.5rem;
                            font-family: "IonIcons";
                            padding: 10px;
                            border-radius: 50%;
                            transform: translate(-50%,-50%);
                        }

                        &:hover {
                            &:before, &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.pswp {
    button {
        &:hover, &:focus {
            background-color: $blue;
        }
    }
}