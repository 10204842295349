.button {
    background-color: $blue;
    text-transform: uppercase;
    font-family: $title_font;
    font-weight: $bold;
    transition: .2s ease-in-out;
    text-align: center;
    color: $white;
    font-size: 1.2rem;
    padding: 0.6rem 1.6rem;
    &:hover, &:focus {
        //background-color: $blue_link;
        background-color: $blue_link;
        color: $white;
    }

    &.clear {
        background-color: $white;
        color: $black;
        margin-top: 20px;
        &:hover, &:focus {
            //background-color: $blue_link;
            background-color: $blue;
            color: $white;

        }

    }

    &.events, &.news {
        margin: 0 auto;
        display: table;

        @media #{$small-only} {
            width: 100%;
        }

    }

    &.underline {
        background: transparent;
        color: $black;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.4rem;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid $black;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        padding-bottom: 5px;
        &:hover, &:focus {
            color: $blue;
            border-color: $blue;
        }
    }
}