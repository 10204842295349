@import "blocks";
@import "wysiwyg";
@import "gallery";
@import "forms";
@import "modal";
@import "video_gallery";
@import "multimedia-combo";

.block
{
    &__wrapper
    {

        &.block__links
        {

            .block__links__links
            {
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        width: 100%;
                        border-bottom: 1px solid $blue;

                        a {
                            display: block;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
}
