.block
{
    &__sitemap {
        ul {
            list-style-type: square;
            li {
                font-size: 1.1rem;
                a {
                    color: $space-grey;
                    &:hover {
                        color: $blue;
                    }
                }

                ul {
                    color: $black;
                    li {
                        font-size: 1rem;
                        a {
                            color: $black;
                        }
                    }
                }
            }

            li + li {
                margin-top: 2px;
            }
        }
    }
}