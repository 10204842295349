.block
{

    &__wysiwyg {
        font-size: 1.2rem;
        p + p {
            margin-top: 20px;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: 25px;
        }

        h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
            margin-top: 25px;

            &.block__introduction {
                margin-top: 0;
            }
        }

        ul {
            list-style-type: square;
        }

        blockquote {
            border-left: 5px solid $green;

            p {
                color: $space-grey;
                font-weight: $regular;
                font-style: italic;
            }
        }

        p, div, li, span {
            a {
                color: $blue;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            a {
                color: $blue;
                &:hover {
                    color: $blue_link;
                }

                &.button {
                    color: $white;
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
}

.block__title {
    margin: 30px 0 10px;
    padding: 0;
    text-transform: capitalize;
    font-size: 2.0rem;
}


//  Featured News Content Block Padding
@media #{$medium-up} {
    .article-column {

    }
}