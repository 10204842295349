#cookie-bar {
    background: $space-grey;
    padding: 15px 20px;
    p {
        text-align: center;
        color: $white;
        margin: 0;
        a {
            background: $white;
            color: $black;
            font-weight: $bold;
            padding: 5px 10px;
            margin-left: 10px;
        }
    }
}

.block
{
    &__page--container {
        margin-top: 20px;
        padding-left:1.0rem;
        padding-right:1.0rem;
        padding-bottom: 100px;
    }

    &__page--sidebar {
        h1 {
            display: block;
            clear: both;
            color: $black;
            text-transform: uppercase;
            text-align: left;
        }

        .stores__grid--filter, .blog__grid--filter {
            margin-top: 40px;
            section {
                &.sidebar--list__filter {
                    margin: 0;

                    .blog--filter__button, .store--filter__button {
                        text-transform: uppercase;
                        color: $black;
                        font-size: 1.2rem;
                        font-weight: $bold;
                        background-color: transparent;
                        font-family: $title_font;
                        display: block;
                        clear: both;
                        border: none;
                        text-align: left;
                        padding: 0;
                        margin-bottom: 10px;
                        transition: .2s ease-in-out;

                        &:hover
                        {
                            color: $blue;
                        }

                    }
                }
            }

            select {
                &.sidebar--select__filter {

                }
            }
        }

    }
}


.row {
    &.reverse-columns {
        padding-left: .9375rem;
        padding-right: .9375rem;

        .row {
            margin: 0;
        }

        @media #{$large-up} {
            padding: 0;
        }

        & > .columns {
            &:first-child {
                @media #{$medium-up} {
                    float: right;
                }
            }
            &:last-child {
                @media #{$medium-up} {
                    float: left;
                }
            }
        }
    }
}



//Index Page listing style
.block {
    &__post--list {

        .post__item + .post__item {
            margin-top: 20px;
        }

        .post__item {
            max-width: 100%;
            width: 100%;
            position: relative;
            background: $grey;

            @media #{$large-up} {
                &:nth-child(even) {
                    .row {
                        & > .columns {
                            &:first-child { float: right; }
                            &:last-child { float: left; }
                        }
                    }
                }
                &:nth-child(odd) {
                    .row {
                        & > .columns {
                            &:first-child { float: left; }
                            &:last-child { float: right; }
                        }
                    }
                }
            }

            .row {
                & > .columns {
                    overflow: hidden;
                }
            }

            .post__item--image {
                width: 100%;
                min-height: 400px;
                height: 100%;
                background-size: cover !important;
                background-position: 50% 50% !important;
                background-repeat: no-repeat !important;
                transition: .4s ease-in-out;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background: $blue;
                    opacity: 0;
                    transition: .2s ease-in-out;
                }

                img {
                    position: absolute;
                    opacity: 0.2;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 60%;
                }

                &:hover {
                    transform: scale(1.05);
                    &:before {
                        opacity: 0.2;
                    }
                }
            }

            .post__item--link {
                transition: .2s ease-in-out;
            }

            .post__item--content {
                position: relative;
                overflow: hidden;
                padding: 30px 20px;
                height: 100%;
                &:hover {

                    img {
                        transform: scale(1.1);
                    }

                    .post__item--image {
                        &:before{
                            opacity: 0.4;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }

                h3 {
                    text-align: left;
                    font-size: 2rem;
                    line-height: 2rem;
                    color: $black;
                    margin: 0;
                    text-transform: uppercase;
                    transition: .2s ease-in-out;
                }

                a {
                    &:hover {
                        h3 {
                            transition: .2s ease-in-out;
                            color: $blue;
                        }
                    }
                }

                p {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }

                .button.underline {
                    bottom: 0px;
                    left: 20px;
                    width: calc(100% - 40px);
                }
            }
        }
    }
}


.mobile-banner-container {
    width: 100%;
    position: relative;

    .background-image-container {
        display: block;
        clear: both;

        img {

        }
    }



    .banner-title-container {
        position: relative;
        background: $blue;
        display: block;
        clear: both;
        padding: 7px 0;

        h2 {
            color: $white;
            text-align: center;
            margin: 0;
            font-size: 1.3875rem;
        }

        a {
            color: $white;

            h2 {
                text-decoration: none;
            }
        }
    }
}
.max-width-logo
{
    .simple__image
    {
        float:left!important;
    }
    img 
    {
        max-width:150px;
    }
}