.block
{
    &.block--video__gallery {

        .flex-video {
            margin-bottom: 0;
        }

        .block--video__gallery__controls {
            background: $grey;

            div {
                padding: 5px;
                color: white;
                display: block;
                cursor: pointer;

                .slick-slide {
                    position: relative;
                    &:focus, &:active {
                        outline: none;
                    }

                    .thumbnail__container {
                        position: relative;
                        padding: 0;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: $space-grey;
                            opacity: 0.6;
                            transition: .2s ease-in-out;
                        }
                    }

                    &.slick-current {
                        .thumbnail__container {
                            &:before {
                                opacity: 0;
                            }
                        }
                    }
                }

            }
        }

        .block--video__gallery__controls {
            overflow: hidden;

            .video {
                float: left;

                iframe {
                    float: left;
                }
            }
        }
    }
}