#floor__plan {
    .close-reveal-modal {
        i {
            font-size: 1.6rem;
            color: $space-grey;
        }
    }

    img {
        max-width: 100%;
        width: 100%;
    }
}