.breadcrumb__wrapper
{
    ul {
        list-style-type: none;
        margin: 0;
        padding: 5px 0;

        li {
            display: inline-block;
            font-weight: $medium;
            font-family: $title_font;

            &:after {
                content: '›';
                color: $grey;
            }

            a {
                padding: 0 10px;
                font-size: 0.8em;
                text-transform: uppercase;
                color: $grey_link;
                &:hover {
                    color: $blue;
                }
            }

            &:first-child {
                a {
                    padding-left: 0;
                }
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}