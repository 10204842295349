nav.side-navigation {
    font-size: 0.8em;
    
    @media #{$medium-up} {
        padding-right: 20px;
    }
    
    ul {
        list-style-type: none;

        & > li {
            width: 100%;

            a {
                display: inline-block;
                width: 100%;
                font-size: 0.8em;
            }

            &.top-parent
            {
                > a {
                    color: black;
                    border-bottom: 3px solid $space-grey;
                    font-weight: bold;
                    padding: 0 0 15px 0;
                    margin-bottom: 15px;
                    font-size: 1em;
                }
            }

            & > ul {
                padding: 0;
                margin: 0;

                & > li.active
                {
                    & > a {
                        font-weight: bold;
                    }
                }

                ul {
                    li {
                        a {
                            padding-left: 20px;
                            &:before
                            {
                                content: "›";
                                display: inline-block;
                                margin-right: 8px;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }
}
