.single__article, .single__offer {

    h1, p {
        text-align: left;
    }


    .date-published {
        text-transform: uppercase;
        color: $black;
        font-family: $title_font;
        font-weight: $medium;
        margin-bottom: 10px;
        font-size: 0.9rem;
        text-align: left;
        @media #{$medium-up} {
            width: 100%;
            float: left;
        }
    }

    .social--sharing {
        width: 100%;
        float: left;
        margin-bottom: 20px;


        .social-buttons {
            text-align: left;
        }

    }

    .article-categories, .offer-categories {
        font-size: 0.8em;
        color: $space-grey;
        font-family: $title_font;

        a {
            font-weight: $medium;
            color: $blue;
        }
    }
}

.single-navigation {
    margin-top: 50px;
    .button {
        font-size: 1.6rem;
        text-transform: uppercase;
        width: 100%;
        padding: 0.8rem 1.6rem;
        background: $grey;
        color: $black;
        font-weight: $medium;
        &:hover {
            background: $black;
            color: $white;
        }

    }
}


.related__articles--container, .related__offers--container {
    height: 500px;
    background: $grey;
    overflowY: hidden;

    .related__articles, .related__offers {
        height: 100%;
        max-height: 100%;
        overflow: scroll;
        margin: 0;
        list-style-type: none;

        li {
            min-height: 125px;
            margin: 20px 20px;

            .related__article--image, .related__offer--image {
                position: relative;
                min-height: 100px;

                img {

                }

                .holding__image {
                    img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        right: 0;
                        width: 80%;
                        opacity: 0.2;
                    }
                }
            }

            h4 {
                font-size: 1.2rem;
                text-transform: uppercase;
                transition: .2s ease-in-out;
                &:hover {
                    color: $blue;
                }
            }

            h5 {
                color: $black;
                font-size: 0.9rem;
            }

            img {

            }
        }

        li + li {
            padding-top: 20px;
            border-top: 2px solid $black;
        }

    }
}

figcaption {
    font-family: $title_font;
}

