.feature-carousel
{
    /* SOME BASICS */
    height: 450px;
    background-color: $blue;
    background-position: 50% 50%;
    background-size: cover;
    margin: 0 !important;

    @media #{$small-only} {
        //background-image: none !important;
    }

    @media #{$medium-up} {
        height: 600px;
    }

    h1, h2, h3, h4, h5, h6, p, span {  color: $white;  }

    .slide {

        //&:before
        //{
        //    content: '';
        //    position: absolute;
        //    top: 0;
        //    left: 0;
        //    width: 100%;
        //    height: 100%;
        //    background: rgba(0,0,0,0.5);
        //    z-index: 1;
        //}

        .slide__content {
            z-index: 2;

            .video-container {
                iframe {
                    max-width: 100%;
                }
            }
        }

        .button {
            margin-top: 20px;
        }

    }

    button {
        z-index: 3;
        &.slick-next {
            right: 20px;
            &:before {
                font-family: $icons;
                content: "\f125";
                font-size: 2rem;
            }
        }
        &.slick-prev {
            left: 20px;
            &:before {
                font-family: $icons;
                content: "\f124";
                font-size: 2rem;
            }
        }
    }
    .slick-dots {
        z-index: 3;
    }

    .slide, .slick-list, .slick-track {
        height: 100%;
    }

    .slide
    {
        background-position: 50% 50%;
        background-size: cover;
        position: relative;
        height: 100%;

        &__content
        {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden;

            &.left
            {
                @media #{$medium-up} {
                    width: 50%;
                    left: 0;
                }
            }

            &.right
            {
                @media #{$medium-up} {
                    width: 50%;
                    right: 0;
                    left: auto;
                }
            }

            & > .row {
                height: 100%;
                .columns {
                    height: 100%;
                }
            }

            .slider-content-bg {
                position: absolute;
                z-index: 0;
                top: 5%;
                transform: scale(1.4);
                left: 0;

                @media #{$medium-up} {
                    top: -5%;
                    transform: scale(1.2);
                }
            }

            .slide-content {
                z-index: 1;
                position: relative;
                top: 45%;
                transform: translateY(-50%);

                @media #{$medium-up} {
                    top: 50%;
                    &.circle-bg-active {
                        padding: 0 0 0 50px;
                    }
                }
                h2 {
                    text-transform: uppercase;
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                    text-align: left;


                    @media #{$medium-only} {
                        font-size: 4rem;
                        line-height: 4rem;
                    }

                    @media #{$large-up} {
                        font-size: 5.3rem;
                        line-height: 5rem;
                    }

                    &.title__subheading {
                        font-size: 2.6rem;
                        line-height: 2.4rem;
                    }
                }

                p, h3 p, h3 {
                    text-transform: uppercase;
                    font-weight: $regular;
                    letter-spacing: 0.3rem;
                    @media #{$medium-up} {

                    }
                }

                .cta-buttons {
                    text-align: left;
                    display: block;
                    margin: 15px auto;

                    .button {
                        padding: 15px 20px;
                        display: inline-block;
                    }

                    .button + .button {
                        margin-left: 15px;
                        background-color: $green !important;
                        &:hover {
                            background-color: $green_link !important;

                        }
                    }
                }


            }
        }

        /**
    *  BACKGROUND VIDEO
    */

        &.js-background-video {
            position: relative;
            background-color: transparent;
            overflow: hidden;

            // Remove the background once the YTPlayer is ready
            &.js-remove-background {
                background-image: none!important;
            }

            .ytplayer-container {
                position: absolute;
                top: 0;
                z-index: -1;
            }

            .ytplayer-shield {
                z-index: -1; // override, to allow users to swipe between slides.
            }

            div[id*="ytplayer-container"] {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                z-index: -2;
            }
        }
    }

    /**
    *  BACKGROUND VIDEO
    */

    &__background-video,
    &__single-background {

        position: relative;
        //background-color: transparent;
        overflow: hidden;

        // Remove the background once the YTPlayer is ready
        &.js-remove-background {
            background-image: none!important;
        }

        .ytplayer-container {
            position: absolute;
            top: 0;
            z-index: -1;
        }

        .ytplayer-shield {
            z-index: -1; // override, to allow users to swipe between slides.
        }

        div[id*="ytplayer-container"] {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            z-index: -2;
        }
    }



    /**
    * SLICK STYLING
    */
    .slick-dots {
        bottom: 10px;

        button {
            border-radius: 0;
            &:before {
                content: '';
                height: 10px;
                width: 10px;
                background: $white;
                color: $white;
                font-size: 1rem;
            }
        }

        li.slick-active {
            button {
                &:before {
                    color: $white;
                }
            }
        }
    }

    // small size overlays
    @media #{$small-only} {
       h1, h2, h3, h4, h5, h6, p
       {  
            display:inline-block;
            margin:0px;
            padding:0px;
            clear:both;
            line-height:1.3;
            span
            {
                //padding: 10px; 
                //box-shadow: 5px rgba($blue,0.7),-5px rgba($blue,0.7); 
                background-color: $blue; 
                background-color: rgba($blue,0.7);
                line-height:1.3;
            }

         }
         .title__subheading
         {
            margin-bottom:10px;
            font-size:1.0rem!important;
         }
    }

}


.feature-carousel__static-overlay {
    position: relative;


    .slide__content {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        height: 100%;
        width: 100%;
        overflow: hidden;


        & > .row {
            height: 100%;

            .columns {
                height: 100%;
            }
        }

        .slider-content-bg {
            position: absolute;
            z-index: 0;
            top: 5%;
            transform: scale(1.4);
            left: 0;

            @media #{$medium-up} {
                top: -5%;
                transform: scale(1.2);
            }
        }

        .slide-content {
            z-index: 1;
            position: relative;
            top: 45%;
            transform: translateY(-50%);

            @media #{$medium-up} {
                top: 50%;
                &.circle-bg-active {
                    padding: 0 0 0 50px;
                }
            }

            h2 {

                @media #{$medium-up}
                { 
                    text-transform: uppercase;
                    font-size: 2.8rem;
                    line-height: 2.8rem;
                    text-align: center;
                    color: $white;
                }

                @media #{$medium-only} {
                    font-size: 4rem;
                    line-height: 4rem;
                }

                @media #{$large-up} {
                    font-size: 5.3rem;
                    line-height: 5rem;
                }
            }

            p, h3 p, h3 {
                text-transform: uppercase;
                font-weight: $regular;
                letter-spacing: 0.3rem;
                @media #{$medium-up} {

                }
            }

            .cta-buttons {
                text-align: center;
                display: block;
                margin: 15px auto;

                .button {
                    padding: 10px 15px;
                    display: inline-block;
                    &:hover, &:focus {
                        background: $white;
                        color: $blue_link;
                    }
                }

                .button + .button {
                    background-color: $green !important;
                    &:hover {
                        background-color: $green_link !important;

                    }
                }
            }
        }
    }
}



//    Style for individual slide types



//    Style for individual slide types

.feature-carousel-2
{
    margin-top:20px;

    //padding-left:0.9375rem;
    //padding-right:0.9375rem;
    @media #{$medium-up} 
    {
        padding-left:0px;
        padding-right:0px;
    } 

    .slide {
        position: relative;

        .slide__content {
            z-index: 2;


            img {
                width:100%;
            }
        }

    }

    /**
    * SLICK STYLING
    */
    .slick-dots {
        bottom: 10px;

        button {
            border-radius: 0;
            &:before {
                content: '';
                height: 10px;
                width: 10px;
                background: $white;
                color: $white;
                font-size: 1rem;
            }
        }

        li.slick-active {
            button {
                &:before {
                    color: $white;
                }
            }
        }
    }
    .slide
    {
        display: none;

        &.slick-initialized,
        &:first-child { display: block }    
    }
}

.slick-initialized
{
    .slide
    {
        display:block!important;
    }
}
