.block
{
    &__content--blocks {

    }


    .block__title {
        margin: 30px 0 10px;
        padding: 0;
        text-transform: capitalize;
        font-size: 2.0rem;
    }

    .block__introduction, &__introduction {
        font-size: 1.0rem;
        text-transform: uppercase;
        color: $space-grey;
        font-weight: $medium;
    }

    &__title {
        padding-top: 30px;
    }

    &__intro {
        padding: 30px 0 0;

        h1 {
            text-align: left;
        }

        p {
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    &__no--articles {
        padding: 30px 0;

        h2 {
            color: $black;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.6rem;
            letter-spacing: 0.2rem;
            line-height: 2rem;

        }

        h3 {
            text-align: center;
            color: $space-grey;
            font-size: 1.5rem;
        }
    }

    &__faqs {
        .accordion {
            li {
                a {
                    background: $grey;
                    font-weight: $medium;
                    text-transform: uppercase;
                    &:hover {
                        background: $green;
                        color: $white;
                        padding-left: 30px;
                    }
                }
                &.active {
                    a {
                        background: $blue;
                        color: $white;
                    }
                }
            }

            li + li {
                margin-top: 5px;
            }
        }
    }

    &__stores--slider__container {
        background: $white;
        padding: 30px 0;

        .stores--slider {
            margin: 0;

            li {
                margin: 10px;
                text-align: center;

                a {
                    .image__holder {
                        background: $white;
                        width: 105px;
                        height: 105px;
                        position: relative;

                        img {
                            margin: 0 auto;
                            display: block;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            padding: 10px;
                            filter: grayscale(100%);
                            opacity: 0.65;
                        }
                    }

                    &:hover {
                        img {
                            //filter: grayscale(0%);
                            opacity: 1;
                        }
                    }
                }
            }

            .slick-prev, .slick-next {
                height: 23px;
                width: 23px;
                transition: .2s ease-in-out;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 23px;
                    width: 23px;
                    background-size: contain;
                }
                &:hover {
                    opacity: 0.75;
                }
            }

            .slick-next {
                background: url('../img/assets/store-slider-next.png');
            }
            .slick-prev {
                background: url('../img/assets/store-slider-prev.png');
            }
        }
    }

    &__related--articles__container {
        background: $grey;
        padding: 30px 0;
        max-height: 300px;
        min-height: 300px;
        overflow: hidden;

        h3 {
            text-align: center;
            color: $black;
            text-transform: uppercase;
            font-size: 2rem;
            letter-spacing: 0.2rem;
        }

        .related--articles__slider {
            margin: 10px 0 0;

            li {
                margin: 10px;
                text-align: center;

                a {
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                }
            }

            .holding__image {
                width: 100%;
                padding: 30px;
            }

            .slick-slide {
                transition: .3s ease-in-out;
                img {
                    width: 90%;
                    transition: .3s ease-in-out;
                    margin-top: 5px;
                    opacity: 0.4;
                }
                &:hover {
                    img {
                        opacity: 1;
                    }
                }

                h4 {
                    opacity: 0;
                    height: 0;
                    transition: .3s ease-in-out;
                }

            }
            .slick-center {
                //opacity: 0;
                img {
                    width: 100%;
                    margin-top: -2.5px;
                    opacity: 1;
                }

                h4 {
                    opacity: 1;
                    height: auto;
                    font-size: 1rem;
                }
            }

            .slick-prev, .slick-next {
                height: 23px;
                width: 23px;
                transition: .2s ease-in-out;
                top: 70px;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 23px;
                    width: 23px;
                    background-size: contain;
                }
                &:hover {
                    opacity: 0.75;
                }
            }

            .slick-next {
                background: url('../img/assets/store-slider-next.png');
            }
            .slick-prev {
                background: url('../img/assets/store-slider-prev.png');
            }
        }
    }

    &__downloads-wrapper, &__download-groups-wrapper {
        table {
            border: 1px solid $grey;

            &.dataTable {
                &.no-footer {
                    border-bottom: 1px solid $grey;
                }
            }

            thead {
                background: $grey;

                th {
                    color: $black;
                    border-bottom: none;

                    &.sorting, &.sorting_asc, &.sorting_desc {
                        @media #{$medium-up} {
                            //width: 100px !important;
                        }
                    }
                }
            }

            tbody {
                td {
                    a {
                        color: $black;
                        transition: .2s ease-in-out;

                        h4 {
                            transition: .2s ease-in-out;
                        }

                        &:hover {
                            color: $blue;

                            h4 {
                                color: $blue;
                            }
                        }

                        &.file-name {
                            h4 {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &__testimonials {
        .block__testimonials-wrapper {
            .block__testimonials-carousel {
                padding-right: 20px;
                blockquote {
                    border-left: 5px solid $green;

                    p {
                        color: $space-grey;
                        font-weight: $regular;
                        font-style: italic;
                    }
                }
            }
        }
    }

    &__featured--article__content {
        border: 3px solid $space-grey;
        position: relative;
        padding: 10px 0 0;
        transition: .2s ease-in-out;
        &:hover {
            border-color: $space-grey;

            h3 {
                color: $black;
            }
            p {
                color: $space-grey;
            }
        }


        h3 {
            text-transform: uppercase;
            text-align: center;
            font-size: 1.2rem;
            color: $space-grey;
            font-weight: $medium;
            letter-spacing: 4px;
            position: relative;
            transition: .2s ease-in-out;
            pointer-events: none;
            padding: 0 10px;

            @media #{$medium-only} {
                font-size: 0.9rem;
            }
        }

        h5 {
            text-align: center !important;
            padding: 5px 10px 0;
            width: 100% !important;
        }

        p {
            margin-top: 15px;
            padding: 0 10px;
            font-size: 1.2rem;
            text-align: center;
            color: $space-grey;
            transition: .2s ease-in-out;

            @media #{$medium-only} {
                font-size: 1rem;
            }
        }

        img {
            padding: 0 10px;
        }

        .article--item__button {
            a {
                width: 100%;
                margin: 0;
                background: $green;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.2rem;
            }
        }

        .article--item__link {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    &__ezine--signup {
        position: relative;

        a {
            display: block;
            text-align: center;
            background: $blue;
            color: $white;
            text-transform: uppercase;
            font-weight: $bold;
            font-size: 1.6rem;
            padding: 0.8rem 1.6rem;
            margin-bottom: 30px;
            &:hover {
                background: $green;
                color: $white;
            }
        }
    }

    &__search-container {
        margin-top: 0;
        padding-bottom: 30px;

        .search-form {
            .search-input {
                color: $black;
                background: $grey;
                font-family: $font;
                font-size: 1.2rem;
                box-shadow: none;
                border: 4px solid #f0f1f2;
                height: 50px;
                border: none;
                &::-webkit-input-placeholder {
                    color: $white;
                    font-family: $font;
                    font-size: 1.2rem;
                    letter-spacing: .1rem;
                }
            }

            input {
                font-family: $font;
            }
        }
    }

    &__search--results__container, &__category--results__container {
        .results__total {
            h3 {
                color: $green;
                a {
                    color: $black;

                    span {
                        color: $blue;
                    }
                }
            }
        }

        article {

            @media #{$small-only} {
                background-color: $grey;
                padding-top: 15px;

                figure {
                    padding: 15px;
                    padding-top: 0;

                    img {

                    }
                }

                .search__article--content {
                    padding: 15px;
                }
            }


            .search__article--content {
                padding-right: 20px;

                .button {
                    @media #{$small-only} {
                        margin-bottom: 0;
                    }
                }
            }

            h3 {
                margin-bottom: 0;
                a {
                    color: $black;
                    &:hover {
                        color: $blue;
                    }
                }
            }

            span {
                text-transform: uppercase;
                color: $black;
                font-family: $font;
                font-weight: $medium;
                margin-bottom: 10px;
                font-size: .9rem;
                text-align: left;
                display: block;
            }

        }

        article + article {
            border-top: 3px solid $grey;
            padding-top: 30px;
            margin-top: 20px;

            @media #{$small-only} {
                border-top: 0;
                padding-top: 15px;
            }
        }
    }

    &__store__contact--details {
        list-style-type: none;
        margin: 30px 0 0;

        li {
            font-weight: $medium;
            font-size: 1.2rem;
            color: $space-grey;

            a {
                color: $space-grey;
                &:hover {
                    color: $blue;
                }
            }
        }

        li + li {
            marign-top: 10px;
        }
    }

    &--full-size-image {

    }

    &__wrapper {
        p {

        }
    }

    &__open--hours {
        width: 100%;
        margin-top: 60px;

        ul {
            list-style-type: none;
            margin: 0;
            display: block;
            clear: both;

            li {
                padding: 10px 0;
                display: block;
                clear: both;

                &.today {
                    background: $green;
                    padding: 10px;

                    h4, p {
                        color: $white;
                    }

                    .day {
                        p {
                            color: $space-grey;
                        }
                    }

                    .time {
                        color: $white;
                        &.closed {
                            color: $white;
                        }
                    }

                    .exception_message {
                        color: $white;
                    }
                }

                .day {
                    display: inline-block;
                    h4, p {
                        text-align: left;
                    }
                    h4 {
                        font-size: 1.1rem;
                        text-transform: uppercase;
                        margin: 0;
                    }
                    p {
                        color: $space-grey;
                        text-transform: uppercase;
                        font-size: 1rem;
                        font-weight: $medium;
                        font-family: $title_font;
                        margin-bottom: 0;
                    }
                }


                .time {
                    float: right;
                    display: inline-block;
                    text-align: right;
                    font-family: $title_font;

                    &.closed {
                        font-weight: $medium;
                        text-transform: uppercase;
                        color: $blue;
                    }
                }

                .exception_message {
                    margin-top: 10px;
                }
            }

            li + li {
                border-top: 1px solid $grey_link;
            }

            li.today + li {
                border-top: none;
            }
        }

        ul + ul {
            margin-top: 80px;
        }
    }

    &__locations {
        .block__title {
            font-size: 1.6rem !important;
        }

        .acf-map {
            margin-top: 0 !important;
        }

        .block__introduction {
            color: $black !important;
            font-size: 1.2rem !important;
            text-transform: none !important;
            @media #{$medium-up} {
                padding-right: 30px;
            }
        }
    }
    &__locations-location {
        margin-top: 20px;
        h2 {
            text-transform: uppercase;
        }

        h3 {
            font-weight: $medium !important;
            font-size: 1.6rem !important;
            color: $black !important;
        }
    }
}

.featured__news--container {
    width: 100%;
    position: relative;

    .row {
        margin: 0;
        max-width: 100%;
    }

    .featured__article + .featured__article {
        margin-top: 20px;
    }
    .featured__article {
        @media #{$large-up} {
            &:nth-child(odd) {
                .row {
                    & > .columns {
                        &:first-child { float: right; }
                        &:last-child { float: left; }
                    }
                }
            }
            &:nth-child(even) {
                .row {
                    & > .columns {
                        &:first-child { float: left; }
                        &:last-child { float: right; }
                    }
                }
            }
        }

        .featured__article--link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .featured__article--image, .featured__article--content {
            position: relative;
        }

        .featured__article--content {
            margin: 0 20px;
            padding-bottom: 60px;
            //height: 100%;
            position: relative;
            overflow: hidden;

            h3 {

                a {
                    color: $black;
                    &:hover {
                        color: $blue;
                    }
                }
            }

        }

        .featured__article--image {
            width: 100%;
            height: 100%;
            background-size: cover !important;
            background-position: 50% 50% !important;
            background-repeat: no-repeat !important;

            img {
                position: absolute;
                opacity: 0.2;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 60%;
            }

            &.no-featured-image {
                //border: 3px solid $black;
            }
        }
    }
}


.simple__image {
    display: inline-block;
}

.map__location {
    margin-top: 30px;
}