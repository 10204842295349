.block
{
    .block__form {

        label {
            color: $black;
            font-family: $title_font;
            font-size: 1rem !important;
            text-transform: uppercase;
        }

        select {
            background-color: $white;
            margin-left: 0;
            color: $black;
            border: 3px solid $black !important;
            font-size: 1.1rem;
            box-shadow: none;
            border: none;
            padding: 5px 10px !important;
            &:hover {
                cursor: pointer;
            }
            &:focus,
            &:active {
                outline: none;
            }
        }

        .gf_progressbar_title {
            margin-left: 0 !important;
        }
        .gf_progressbar {
            width: 100%;
            padding: 10px 0;
            border-radius: 5px;
            box-shadow: none;
            background-color: transparent;
            &:after {
                background-color: rgba($grey, 0.2);
            }

            .gf_progressbar_percentage {
                font-family: $title_font;
                font-weight: $bold;
                text-shadow: none;

                &.percentbar_blue {
                    background-color: $blue;
                    background: $blue;

                }
            }
        }

        .gfield_required {
            color: $blue_link;
        }

        .gform_wrapper
        {
            margin-top: 0;

            .gfield_radio, .gfield_checkbox {
                li {
                    input[type="radio"], input[type="checkbox"] {
                        margin: 0 0 0 1px !important;
                    }
                    label {
                        margin-top: 0;
                        margin-left: 5px;
                    }
                }
            }

            ul.gform_fields {
                li.gfield:first-child {
                    margin-top: 0;
                }
            }

            input:not([type="button"]):not([type="submit"]), textarea {
                background: $grey;
                color: $black;
                box-shadow: none;
                padding: 5px 10px !important;
                letter-spacing: 0.1rem;
                border: none !important;

                &::-webkit-input-placeholder {
                    color: $space-grey !important;
                    text-transform: uppercase;
                }

                &:hover {
                    cursor: grab;
                }
                &:focus,
                &:active {
                    outline: none;
                }
            }

            input:not([type="button"]):not([type="submit"]) {
                height: 50px;
                border: none !important;

            }

            .button {
                float: left;
                display: block;
                height: auto !important;
            }

            .gfield {
                &.gfield_error {
                    border: none;
                    background: transparent;

                    .gfield_label {
                        color: $red;
                    }

                    input, select, textarea {
                        border: 4px solid $red !important;
                    }

                    input[type="file"] {
                        border: none !important;
                        color: $red !important;
                    }

                    .validation_message {
                        color: $red;
                        padding-left: 0;
                        padding-top: 5px;
                    }

                    .name_first, .name_last, .ginput_container_address {
                        label {
                            color: $space-grey;
                        }
                    }
                }
            }


            .gfield_contains_required {
                span {
                    color: $red;
                }
            }

            .validation_error {
                color: $white;
                border: none;
                background: $red;
            }
        }
    }
}

.gform_confirmation_message_2 {
    color: $green;
    font-family: $title_font;
    font-size: 1.4rem;
}

#mc_embed_signup {
    position: relative;
    form {
        padding-top: 0 !important;
    }

    .indicates-required {
        position: absolute;
        top: 0;
        right: 0;
    }

    label {
        font-weight: $bold;
        font-family: $title_font;
        color: $black;
        font-size: 1.2rem;
    }

    .mc-field-group {
        padding-bottom: 0 !important;
    }

    #mce-responses {
        margin: 0;
        padding: 0;
        top: 0;
    }

    .mce_inline_error {
        color: $red;
        border-color: $red;
    }

    #mce-success-response {
        padding-left: 0 !important;
    }

    input {
        border-radius: 0 !important;
        &.mce_inline_error {
            border: 3px solid $red !important;
        }
    }
    .mce_inline_error {
        color: $red !important;
        padding-left: 0 !important;
        background-color: transparent !important;
    }

    input:not([type="button"]):not([type="submit"]), textarea {
        background: $grey_link;
        color: $black;
        border: none;
        box-shadow: none;
        padding: 5px 10px !important;
        letter-spacing: 0.1rem;

        &::-webkit-input-placeholder {
            color: $space-grey !important;
            text-transform: uppercase;
        }

        &:hover {
            cursor: grab;
        }
        &:focus,
        &:active {
            outline: none;
        }
    }

    input:not([type="button"]):not([type="submit"]) {
        height: 45px;
    }

    #mce-success-response {
        color: $green;
        font-family: $title_font;
        padding-left: 0 !important;
        margin-bottom: 20px;
    }

    #mce-error-response {
        color: $black;
        font-family: $title_font;
        padding-left: 0 !important;
        margin-bottom: 20px;

        a {
            color: $blue;
            &:hover {
                color: $blue_link;
            }
        }
    }


}