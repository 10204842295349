.pagination {
    display: block;
    width: 100%;
    height: 40px;
    margin: 20px 0 0 !important;
    position: relative;

    select {
        border: 3px solid $black;
        font-weight: $bold;
        background-color: $white;
        color: $black;
    }

    .pagination-previous, .pagination-next {
        display: none;
    }

    @media #{$large-up} {
        .pagination-previous, .pagination-next {
            display: block;
            color: $white;
            margin: 0;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;

            a {
                background: $blue;
                color: $white;
                border-radius: 0;
                position: relative;
                width: 100%;
                height: 100%;
                transition: .2s ease-in-out;

                span {
                    position: absolute;
                    top: 45%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    text-align: center;
                    font-size: 1.4rem;
                }
                &:hover {
                    opacity: 0.6;
                    background: $blue_link;
                }

            }
        }
        .pagination-previous {
            left: .9375rem;
        }
        .pagination-next {
            right: .9375rem;
        }

        .page-number-links {
            margin: 0 auto;
            text-align: center;
            float: none;
            width: 90%;

            li {
                float: none;
                display: inline-block;
                font-size: 1.2rem;

                a {
                    border-radius: 0;
                }

                span {
                    background: $green;
                    color: $black;
                    padding: 5px 10px;
                }
            }
        }
    }
}