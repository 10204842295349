@import "foundation/5/foundation";
// @import "foundation/6/foundation";

@import "base/variables";
@import "base/mixins";
@import "acf/acf";
@import "modules/all";
@import "pages/all";
@import "plugins/photoswipe";

.page__content__wrapper
{
    padding: 60px 0;
}


//