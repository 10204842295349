.faqs
{
    &__wrapper {}

    &__faq
    {
        margin-bottom: 30px;
        border: 1px solid black;
        padding: 30px;
    }
}

.block__faqs {
    margin-bottom: 60px;
}