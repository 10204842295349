figure {
  margin: 0;
}

// Photos //
.block__wrapper {
    &.block__gallery {
        .block__gallery__image {
            width: 100px;
        }
    }
}

// Videos //
.block__wrapper {
    &.block__videos {
        .block__videos-video {
            display: inline-block;
            width: 230px;
        }
    }
}