.block
{
    &__offers--grid {

        .offer--item {
            float: left;
            width: 100%;
            height: auto;
            padding-left: .9375rem;
            padding-right: .9375rem;
            position: relative;
            margin-bottom: 30px;
            border: 2px solid $white;
            background-color: $grey;
            padding-top: 15px;
            transition: all .3s ease;


            @media #{$medium-only} {
                //width: 50%;
            }

            @media #{$large-up} {
                margin-bottom: 30px;
            }

            @media #{$medium-up} {
                // height: 600px;
            }

            .offer--item__link-all {
                width: 100%;
                height: 100%;
                z-index: 5;
                position: absolute;
                top: 0;
                left: 0;
            }

            &:hover {
                border: 2px solid $hover_blue;

                a {
                    h3 {

                        transition: .2s ease-in-out;
                        color: $hover_blue;
                    }

                    &.underline {
                        color: $hover_blue;
                        border-color: $hover_blue;
                    }
                }
            }

            .offer--item__image {
                overflow: hidden;
                height: 0px;
                padding-bottom: 100%;
                display: block;
                width: 100%;
                margin-bottom: 15px;
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: 100%;
                    pointer-events: none;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }

                .offer--item__link {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .offer--item__content {
                //position: absolute;
                //bottom: 0;
                //left: 0;
                //right: 0;
                //width: 100%;
                //min-height: 20%;
                //z-index: 2;
                //background: $white;
                //padding: 15px;

                h3 {
                    z-index: 2;
                    color: $black;
                    position: relative;
                    text-align: left;
                    text-transform: uppercase;
                    font-size: 1.6rem;
                    line-height: 1.6rem;
                    pointer-events: none;

                    @media #{$large-up} {
                        font-size: 2rem;
                        line-height: 2rem;
                    }
                }

                a {
                    &:hover {
                        h3 {
                            transition: .2s ease-in-out;
                            color: $blue_link;
                        }
                    }
                }

                .button {
                    margin-top: 20px;
                    position: relative;
                }
            }
        }
    }
}

.block__related--stores {
    margin: 30px 0;

    .related--store {
        margin-bottom: 20px;
    }

    .related__store--image {
        border: 3px solid $black;
        height: 0;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;

        @media #{$large-up} {
            //height: 255px;
        }


        .holding__image {
            img {
                position: absolute;
                width: 80%;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                opacity: 0.2;
                margin: 0 auto;
            }
        }

        img {
            padding: 20px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.blahblahblah {
    display: none;
}