footer {
    background: $black;

    .footer__menu {
        padding: 20px 0;

        ul {
            margin: 0 auto;
            text-align: center;
            list-style-type: none;

            &.left-align-menu {
                text-align: left;
                li {

                }
            }

            &.right-align-menu {
                text-align: right;
                li {
                    text-align: right;
                }
            }

            li {
                text-align: left;
                display: block;
                width: 100%;
                position: relative;
                font-size: 1rem;

                @media #{$medium-up} {
                    text-align: center;
                }

                a {
                    color: $white;
                    margin-bottom: 2px;
                    &:hover {
                        color: $blue;
                    }

                    @media #{$medium-up} {
                        margin-bottom: 6px;
                        width: 100%;
                        display: block;
                    }
                }
            }
        }

        @media #{$medium-up} {
            ul {
                margin: 0 auto;
                text-align: center;

                li {
                    text-align: center;
                    width: auto;
                    display: inline-block;
                    margin: 0 15px;
                    text-transform: uppercase;
                    font-size: 0.8rem;

                    a {
                        width: auto;
                        display: inline-block;
                    }
                }
            }
        }
    }
}


// Search Bar just above footer
.search-container-footer {
    max-width: 400px;
    margin: 0 auto;
    @media #{$medium-up} {
        margin:0px;
    }
    display: block;
    margin-bottom: 25px;

    form {
        position: relative;

        .search-input {
            border: 4px solid $grey;
            box-shadow: none;
            height: 50px;
            margin: 0;
        }

        &:after {
            content: "\f4a4";
            font-family: $icons;
            top: 14px;
            position: absolute;
            right: 20px;
        }
    }
}