.block__locations
{
    h5 {
        text-align: center;
        color: $space-grey;

        span {
            text-transform: uppercase;
        }
    }

    &__directions {
        margin-bottom: 20px;
        h3 {
            color: $black;
            text-transform: uppercase;
            font-size: 1.8rem;
            letter-spacing: 0.1rem;
        }

        li, p {
            font-size: 1.2rem;
            color: $space-grey;
        }

        @media #{$medium-up} {
            .medium-6:first-child {
                padding-right: 30px;
            }
        }
    }

    .acf-map {
        margin-top: 20px;
    }

    .block__locations-address {
        text-align: center;

        span {
            display: inline-block;
            &:after {
                content: ",";
            }
            &:last-child:after {
                content: "";
            }
        }
    }

    &__address {
        display: block;
        clear: both;
        margin-bottom: 50px;

        h5 {
            text-align: center;
            font-weight: 500;
            color: #454a48;
        }

        .block__locations-contact {
            text-align: center;
            span {
                display: inline-block;
            }

            h5 {
                display: inline-block;

                a {
                    color: $space-grey
                    &:hover {
                        color: $blue;
                    }
                }

                &.email {
                    float: left;
                }
                &.phone {
                    float: right;
                }
            }
        }


    }

    .block__locations-location {
        margin-bottom: 20px;
        display: block;
        clear: both;
    }

    .block__locations-contact {
        text-align: center;
        span {
          display: inline-block;
        }

        h5 {
            display: inline-block;

            a {
                color: $space-grey;
                &:hover {
                    color: $blue;
                }
            }

            &.email {
                float: left;
            }
            &.phone {
                float: right;
            }
        }
    }

}