.block {
    &__grid--navigation {
        margin-bottom: 30px;
        .grid--item {
            float: left;
            margin-bottom: 30px;
            width: 50%;
            height: 250px;
            padding-left: .9375rem;
            padding-right: .9375rem;

            @media #{$medium-up} {
                width: 25%;
                margin-bottom: 0;
                height: 350px;
            }

            @media #{$large-up} {
                height: 400px;
            }

            .grid--item__content {
                background-size: cover !important;
                overflow: hidden;
                height: 100%;
                position: relative;
                transition: .4s ease-in-out;
                background: $blue;

                &:hover {
                    transform: scale(1.05);
                    background: $blue_link;
                }

                .grid--item__link {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    &:hover {
                        cursor: pointer;
                        cursor: hand;
                    }
                }

                img {
                    max-width: 75%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-60%);
                    margin: 0 auto;
                }

                .item__link {
                    pointer-events: none;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 20px;
                    transition: .2s ease-in-out;


                    h3 {
                        text-align: center;
                        text-transform: uppercase;
                        color: $white;
                        margin-top: 35px;
                        font-size: 1.2rem;
                        line-height: 1.2rem;
                        margin: 0;
                        padding: 0 10px;

                        @media #{$medium-only} {
                            font-size: 1.6rem;
                            line-height: 1.6rem;
                        }


                        @media #{$large-up} {
                            font-size: 2.4rem;
                            line-height: 2.3rem;
                            padding: 0 20px;
                        }
                    }

                }

            }
        }
    }
}

.homepage__content--blocks {
    padding: 40px 0;
    .block__wysiwyg {
        .block__title {
            font-size: 3.3rem;
            line-height: 3rem;
            text-transform: uppercase;
            @media #{$medium-up} {
                font-size: 4.3rem;
                line-height: 4rem;
            }
        }
    }
}