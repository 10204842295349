html {
    overflow-x: hidden;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    &.nav-is-open {
        overflow-y: hidden;

        body {
            overflow-x: hidden;
        }
    }
}

body {
    position: relative;

    &.user-logged-in {
        nav.primary {
            display: block;
            clear: both;

            > ul {
                @media #{$small-only} {
                    //top: 170px;
                }

                @media #{$medium-only} {
                    //top: 157px;
                }
            }
        }
    }
}

header {
    padding: 0;
    background: $white;
    display: block;
    clear: both;

    @media #{$medium-only} {
        padding: 20px 0 0 0;
    }

    @media #{$large-up} {
        padding: 30px 0 0 0;
    }

    .social {
        &.mobile-only {
            margin-bottom: 0;
            @media #{$small-only} {
                background: $blue;
                padding: 7px 0;
                margin-bottom: 10px;

                .social-icons {
                    float: right;
                    i {
                        color: $black;
                        font-size: 1.4rem;
                        text-align: right;
                        &:hover {
                            color: $white;
                        }
                    }

                    span + span {
                        margin-left: 10px;
                    }
                }

                .centre-icons {
                    float: left;
                    i {
                        color: $black;
                        font-size: 1.4rem;
                        text-align: left;
                        &:hover {
                            color: $white;
                        }
                    }

                    span + span {
                        margin-left: 10px;
                    }
                }
            }
        }
    }

   /* .social-media {
        position: relative;
        padding-top: 20px;

        @media #{$small-only} {
            padding-top: 0;
            margin-bottom: 20px;
        }

        span {
            margin-left: 0.8rem;
            position: relative;

            a {
                color: $black;
                i {
                    position: relative;
                    font-size: 1.8rem;
                    top: 0;
                    left: 0;

                    @media #{$medium-up} {
                        font-size: 2.8rem;
                        top: 7px;
                    }
                }

                &:hover {
                    color: $purple;
                }
            }


            &:first-child {
                margin-left: 0;
            }

            a {
                img {
                    max-width: 30px;
                }

                &:hover {
                    img {
                        opacity: 0.8;
                    }
                }
            }
        }

        @media #{$large-up} {
            float: right;
            padding-top: 5px;
        }
    }*/
    .social-media {
        position: relative;
        padding-top: 20px;

        @media #{$small-only} {
            padding-top: 0;
            margin-bottom: 20px;
        }

        span {
            margin-left: 0.8rem;
            &.twitter {
                position: relative;
                a {
                    color: $twitter;
                    i {
                        position: absolute;
                        font-size: 1.8rem;
                        top: 7px;
                        left: 0;
                    }
                }
            }

            &:first-child {
                margin-left: 0;
            }

            a {
                img {
                    max-width: 30px;
                }

                &:hover {
                    img {
                        opacity: 0.8;
                    }
                }
            }
        }

        @media #{$large-up} {
            float: right;
            padding-top: 5px;
        }
    }
    .logo {
        
        @media #{$small-only} {
            max-width: 200px;
            width: 100%;
            padding: 0 0 30px;
        }

        @media #{$medium-only} {
            max-width: 260px;
            width: 75%;
            float: none;
            margin: 0 auto;
        }

        @media #{$medium-up} {
            padding: 0 0 40px;
            display: block;
            text-align: center;
        }

        @media #{$large-up} {
            width: 45%;
            margin: 0 auto;
            img {
                margin: 0 auto;
            }
        }

    }

    .time__today {
        float: left;
        position: relative;

        @media #{$large-up} {
            margin-left: 30px;
            //display: none;
        }

        span {
            text-align: left;
            position: relative;
            color: $black;
            text-transform: uppercase;
            &:before {
                content: '';
                position: absolute;
                left: -40px;
                top: 2px;
                height: 30px;
                width: 30px;
                background: url('../img/assets/clock-icon.png');
                background-size: contain;
            }

            .times {
                display: block;
                clear: both;
                margin: 0 auto;
                text-align: left;
                font-weight: $bold;
            }
        }
    }
}

html.nav-is-open {
    ul.mobile-nav {
        right: 0;
        overflow: scroll !important;
    }
}

.mobile--open-times {
    background: $green;
    width: 100%;
    padding: 7px 20px;

    .time__today {
        width: 100%;
        span {
            text-align: center;
            color: $white;
            margin: 0 auto;
            padding-left: 20px;
            display: table;
            position: relative;
            text-transform: uppercase;
            &:before {
                content: '';
                position: absolute;
                left: -10px;
                top: 2px;
                height: 20px;
                width: 20px;
                background: url('../img/assets/clock-icon-white.png');
                background-size: contain;
            }

            .times {
                display: inline-block;
                clear: none;
                padding-left: 5px;
                font-weight: $medium;
            }
        }
    }
}

html.nav-is-open {
    #primary-nav {
        &.open {
            .menu-item-has-children {
                position: relative;
                &:hover {
                    background: $green_link;

                    & > a {
                        color: $white;
                        &:after {
                            color: $white;
                        }
                    }
                }
                & > a {
                    font-size: 1.4rem;
                    position: relative;
                    transition: .2s ease-in-out;
                }
                &:after {
                    content: '\f123';
                    font-size: 1rem;
                    font-family: $icons;
                    transition: .2s ease-in-out;
                    color: $black;
                    left: auto;
                    right: 25px;
                    position: absolute;
                    top: 15px;
                    //transform: translateY(-50%);
                    pointer:hand;
                    pointer:cursor;

                }
                &.open-subnav {
                    background: $green_link;
                    & > a {
                        &:after {
                            transform: rotate(180deg);
                            top: 28%;
                        }
                    }

                    .sub {
                        display: block;
                        background: $green_link;

                        li {
                            a {
                                color: $white;
                                padding: 5px 15px 5px 30px;
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                .sub {
                    display: none;
                }
            }
        }
    }
}


// *********************
// MAIN NAVIGATION
// ***********
nav.primary {
    transition: .3s ease-in-out;
    background: $green;
    position: absolute;
    width: 0;
    height: 100%;
    z-index: 5;
    margin-top: 0;
    right: -100%;
    overflow: hidden;

    &.open {
        right: 0%;
        width: 100%;

        @media #{$medium-only} {
            right: -65%;
        }
    }

    @media #{$large-up} {
        margin: 0 auto;
        position: relative;
        //height: inherit;
        min-height: calc(100% - $header_height_mobile);
        right: 0;
        left: 0;
        width: 100%;
        border-top: 0;
        margin-top: 30px;
        overflow: visible;
        display: block;
        clear: both;
    }

    & > .row {
        max-width: 100rem;
        height: 100%;

        @media #{$large-up} {
            max-width: 80rem;
            height: inherit;
        }

        .navigation {
            height: calc(100% - 200px);
            @media #{$medium-only} {
                padding-top: 38px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            height: 100%;
            background: $green;
            overflow: scroll;
            padding-bottom: 60px;

            @media #{$large-up} {
                display: block;
                padding-bottom: 0;
                overflow: visible;
            }
        }

        & > ul, .sub-inner {
            width: 100%;
            max-width: $row-width;
            margin: 0 auto;

            @media #{$large-up} {
                width: initial;
            }
        }

        // If the list item has a dropdown then the LI is clicked to open the dropdown and the HREF is clicked to open that page.
        & > ul > li {
            display: inline-block;
            width: 100%;
            padding: 1.5em 2em 1.5em 2em;

            @media #{$large-up} {
                float: left;
                width: initial;
                padding: 0.5rem 1rem;
                text-align: center;
            }

            &:hover {
                background: $green_link;
                color: $white;
            }

            & > a {
                font-weight: 700;
                color: $black;
                display: inline-block;

                &:hover {
                    color: $white;
                }
            }

            &.open a, &.open > a:hover, li:hover {
                color: $white;
                background: $green_link;
            }
        }

    }

    // OLD NAV STYLE BELOW
    .navigation {
        @media #{$large-up} {
            display: block;
            clear: both;
            width: 100%;
            margin: 0;
            height: auto;
        }

        ul {
            overflow-y: scroll;
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: left;

            @media #{$large-up} {
                margin: 0 auto;
                text-align: center;
                display: block;
                overflow-y: visible;
                height: auto;
            }

            li {
                text-align: left;
                text-transform: uppercase;
                font-family: $title_font;
                font-weight: $bold;
                letter-spacing: 1px;
                transition: .2s ease-in-out;

                @media #{$large-up} {
                    text-align: center;
                    display: inline-block;
                    position: relative;
                    margin: 0 15px;
                    padding-bottom: 0;
                }

                a {
                    padding: 10px 20px;
                    display: inline-block;
                    color: $black;
                    &:hover {
                        color: $white;

                        @media #{$large-up} {
                            background: transparent !important;
                        }
                    }

                    @media #{$large-up} {
                        color: $black;
                        display: block;
                    }
                }

                @media #{$large-up} {
                    &:hover {
                        color: $white;
                        background: $green_link;
                        .sub {
                            display: block;
                            height: auto;
                            width: 200px;
                            z-index: 2;
                        }
                    }

                    .sub {
                        display: none;
                        background: $green;
                        position: absolute;
                        top: 50px;

                        ul {
                            li {
                                font-size: 0.9rem;
                                width: 100%;
                                margin: 0;
                                padding: 0;
                                text-align: left;
                                background: $green;
                                transition: .2s ease-in-out;
                                a {
                                    width: 100%;
                                    text-align: left;
                                    color: $white;
                                    &:hover {
                                        color: $white;
                                    }
                                }
                                &:hover {
                                    color: $white;
                                    background: $green_link;
                                }
                            }
                        }
                    }
                }

                .sub {
                    margin-bottom: 10px;
                    ul {
                        li {
                            text-transform: capitalize;
                            font-weight: $regular;

                            @media #{$large-up} {
                                text-transform: uppercase;
                                font-family: $title_font;
                                font-weight: $medium;
                                letter-spacing: 1px;
                            }
                        }
                    }
                }
            }
        }
    }


    & > ul, .sub-inner {
        width: 100%;
        max-width: $row-width;
        margin: 0 auto;
    }

    & > ul > li {
        display: inline-block;

        & > a {
            font-weight: 700;
            padding: 1.5em 2em 1.5em 2em;
            color: #999;
            display: inline-block;

            &:hover {
                color: $white;
            }
        }

        &.open a, &.open > a:hover
        {
            color: #fff;
            background: $green_link;
        }
    }

}




html.nav-is-open nav.primary > ul
{
    // display: block;
}

/* Examples for media queries */

@media screen and (max-width: 52.75em) {

    nav.primary {
        font-size: 80%;
    }

}

@media screen and (max-width: 43em) {

    nav.primary {
        font-size: 120%;
    }

    nav.primary > ul,
    nav.primary .sub-inner {
        width: 100%;
        padding: 0;
    }

    nav.primary .sub-inner {
        padding: 0 2em;
        font-size: 75%;
        margin-bottom: 15px !important;

        li {
            text-transform: capitalize !important;
            font-weight: $regular !important;
        }
    }

    nav.primary > ul > li {
        display: block;
        //border-bottom: 4px solid $primary-color;
    }

    nav.primary > ul > li > a {
        display: block;
        padding: 1em 3em;
    }

    nav.primary .sub {
        position: relative;
    }

    .sub h4 {
        padding-top: 0.6em;
    }

}

@media screen and (max-width: 36em) {
    nav.primary .sub-inner > div {
        width: 100%;
        float: none;
        padding: 0 2em;
    }
}


.menu-button
{
    float: right;
    display: block;
    padding: 10px;
    margin-top: 20px;

    *, *:before, *:after
    {
        transform: translate3d(0,0,0);
    }

    #navicon {
        display: block;
        pointer-events: initial;
        position: relative;
        float: left;
        margin-top: 0;

        .bar
        {
            width: 30px;
            height: 2px;
            background-color: $black;
            transform: rotate(0deg);
            transition: background-color ease 250ms;

            &:nth-of-type(1)
            {
                animation: dissappearRev 0.5s ease;
                animation-fill-mode: forwards;
            }

            &:nth-of-type(2) {
                margin-top: -10px;
                animation: squishTopRev 0.5s ease;
                animation-fill-mode: forwards;
            }

            &:nth-of-type(3) {
                margin-top: 22px;
                animation: squishBottomRev 0.5s ease;
                animation-fill-mode: forwards;
            }
        }



        html.nav-is-open &
        {
            .bar
            {
                &:nth-of-type(1) {
                    animation: dissappear 0.5s ease;
                    animation-fill-mode: forwards;
                }

                &:nth-of-type(2) {
                    animation: squishTop 0.5s ease;
                    animation-fill-mode: forwards;
                }

                &:nth-of-type(3) {
                    animation: squishBottom 0.5s ease;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

.additional-footer__navigation {
    width: 100%;
    background: $grey;
    margin-top: 80px;
    margin-bottom: 20px;
    padding: 30px 0 60px;

    @media #{$medium-up} {

    }

    ul {
        list-style-type: none;
        margin: 0;
        li {
            display: block;
            clear: both;
            text-transform: uppercase;
            font-size: 1.1rem;
            @media #{$medium-up} {
                font-size: 1.2rem;
            }

            &.top-link {
                padding-bottom: 15px;
                & > a {
                    color: $blue;
                    font-weight: $bold;
                    font-family: $title_font;
                }

                @media #{$medium-up} {
                    font-size: 1.4rem;
                }
            }

            a {
                width: 100%;
                display: block;
                color: $black;
                &:hover {
                    color: $green;
                    cursor: pointer;
                    cursor: hand;
                }

                @media #{$medium-up} {

                }
            }

            .sub {

            }
        }
    }

    .location--details {
        a {
            display: block;
            clear: both;
            font-size: 1.1rem;
            color: $blue;
            font-weight: $bold;
            &:hover {
                color: $green;
            }

            @media #{$medium-up} {
                font-size: 1.1rem;
            }     
        }
    }
}



@keyframes squishBottom {
    0% {
        margin-top: 17px;
        transform: rotate(0deg);
    }
    50% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    75% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    100% {
        margin-top: -3px;
        transform: rotate(-135deg);
    }
}

@keyframes squishTop {
    0% {
        margin-top: -12px;
        transform: rotate(0deg);
    }
    50% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    75% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    100% {
        margin-top: -3px;
        transform: rotate(-45deg);
    }
}


@-moz-keyframes dissappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    65% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes dissappear {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    65% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes squishBottomRev {
    0% {
        margin-top: -3px;
        transform: rotate(-135deg);
    }
    50% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    75% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    100% {
        margin-top: 18px;
        transform: rotate(0deg);
    }
}

@keyframes squishTopRev {
    0% {
        margin-top: -3px;
        transform: rotate(-45deg);
    }
    50% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    75% {
        margin-top: -3px;
        transform: rotate(0deg);
    }
    100% {
        margin-top: -12px;
        transform: rotate(0deg);
    }
}

@keyframes dissappearRev {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.reduce-font
{
    font-size:0.8rem!important;
}   