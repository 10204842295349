.block {
    &__stores--list {

        .store__item {
            max-width: 50%;
            padding-bottom: 50%;
            width: 100%;
            padding-left: .9375rem;
            padding-right: .9375rem;
            float: left;
            position: relative;
            transition: none;

            @media #{$medium-only} {
                max-width: 50%;
                padding-bottom: 50%;
            }

            @media #{$large-up} {
                max-width: 33.3333%;
                padding-bottom: 33.3333%;

            }

            &.active {
                .stores__grid--content {
                    &:before {
                        background-color: transparent !important;
                    }

                    .image__holder {
                        img {
                            filter: grayscale(0%);
                            opacity: 1;
                        }
                    }
                }
            }

            .stores__grid--content {
                width: 91%;
                height: 91%;
                position: absolute;
                margin: 0 auto;
                top: 0;
                left: 0;
                right: 0;
                background-size: cover !important;

                @media #{$small-only} {
                    width: 88%;
                    height: 88%;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: $grey;
                    transition: .2s ease-in-out;
                    z-index: 1;
                }
                &:hover {
                    &:before {
                        background-color: $black !important;
                    }

                    .image__holder {
                        img {
                            filter: grayscale(0%);
                            opacity: 1;
                        }
                    }
                }

                .image__holder {
                    background: $white;
                    width: 80%;
                    height: 80%;
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    right: 0;
                    display: block;
                    margin: 0 auto;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$medium-up} {
                        width: 70%;
                        height: 70%;
                    }

                    img, h3 {
                        z-index: 2;
                        max-width: 80%;
                        width: 100%;
                        //max-height: 60%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        top: 50%;
                        transform: translateY(-50%);
                        pointer-events: none;
                        text-align: center;
                        font-size: 1.4rem;

                        @media #{$small-only} {
                            max-height: 120px;
                            max-width: 120px;
                            width: 90%;

                        }
                    }

                    img {
                        @media #{$large-up} {
                            filter: grayscale(100%);
                            opacity: 0.65;
                        }
                    }
                }

                .stores__grid--link {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0.75;
                    background: $space-grey;
                    &:hover {
                        background: $space-grey !important;
                        opacity: 0.4;
                        cursor: pointer;
                    }
                }
            }
        }

    }
}

.single__store--content {

    h1 {
        text-align: left;
    }

    p {
        text-align: left;
        margin-top: 20px;
    }

    h4 + p {
        margin-top: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 1.2rem;
        margin-top: 30px;
    }

    img {
        width: 100%;

        @media #{$medium-only} {
            width: 90%;
        }
    }

    .store__unit {
        margin-bottom: 20px;
    }

    .opening--hours {
        list-style-type: none;
        margin: 0;
        display: block;
        clear: both;

        li {
            font-weight: $medium;
            font-size: 1.2rem;
            display: block;
            padding: 10px 0;
            clear: both;
            font-family: $title_font;

            &.today {
                span {
                    color: $blue;
                }
            }

            span {
                text-align: left;
                padding-bottom: 10px;

                position: relative;
                display: inline-block;
                &.day {
                    width: 110px;
                    float: left;
                    text-align: left;
                }

                &.time {
                    margin-left: 20px;
                    float: right;
                    text-align: right;
                }
            }

            &.no__open--hours {
                margin-top: 50px;
                padding-right: 20px;
                span {
                    font-weight: normal;
                }
            }
        }

        li + li {
            border-top: 1px solid $grey;
        }
    }

    .block__store__contact--details {
        display: block;
        clear: both;

        h4 {
            margin-top: 0;
        }

        li {
            margin-bottom: 20px;
            font-weight: $medium;

        }

    }
}

.store-logo-right {
    float: right;

    .social-sharing {
        .social-bttons {
            text-align: right !important;
        }
    }
}

body.store--details__open {
    @media #{$small-only} {
        overflow: hidden;

        .stores__item--details.open {
            overflow: scroll !important;
        }
    }
}


//  Tour Modal
#tourModal {
    min-height: 75%;
    height: 100%;
    padding-top: 50px;

    @media #{$medium-up} {
        height: 75%;
    }

    object { 
        width: 100%;
        height: 100%;
    }

    .close-reveal-modal {
        color: $black;
        top: 6px;
        right: 20px;
        transition: all .2s ease;

        &:hover {
            color: $pink;
        }
    }
}