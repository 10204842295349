


.testimonials
{
    &__wrapper {}

    &__testimonial
    {
        margin-bottom: 30px;
        border: 1px solid black;
        padding: 30px;
    }
}