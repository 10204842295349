/* Social Media */
$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;
$blue: #39ABD9;
$hover-blue: #0092a0;
$text-grey: #454A48;
$heather: #AEBCBE;
$light-grey: #DDE4E5;
$store-item-grey: #A1B2B6;
$red: #e74c3c;
$blue_link: #9ECDCB;

/* Site Variables */
$primary-color: #3F2159;
$purple: #3F2159;
$pink: #DC2C5E;
$pink_link: #DC1A5A;
$grey: #E3E3E3;
$grey_link: #D1D1D1;
$black: #1B1B19;
$white: #ffffff;
$space-grey: #2C3335;
$green: #a5c30d;
$green_link: #7c930a;
$blue: #00abc8;
$blue_link: #00859c;

$header_height_mobile: 181px;
$header_height_desktop: 217px;

::selection {
    color: $white;
    background: $green;
}


/* Site Fonts */
$title_font: "neutraface-text", sans-serif;
$font: "futura-pt", sans-serif;
$icons: "Ionicons";


/* Font Weights */
$regular: 300;
$medium: 500;
$bold: 700;



/* Typography */
/*h1, h2, h3, h4,
h5, h6 {
    font-family: $font !important;
    font-weight: $bold;
    color: $black;
    text-transform: uppercase;
}

h1 {
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    line-height: 2.2rem;

    @media #{$medium-up} {
        font-size: 2.7rem;
        letter-spacing: 0.2rem;
        line-height: 3rem;
        text-transform: uppercase;
    }
}*/
/* Typography */
h1, h2, h3, h4,
h5, h6, p, span,
li, a {
    font-family: $font !important;
}

h2, h4,
h5, h6 {
    font-weight: $medium;
}

h1 {
    color: $black;
    text-transform: uppercase;
    font-weight: $medium;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
    line-height: 2.2rem;

    @media #{$medium-up} {
        font-size: 2.5rem;
        letter-spacing: 0.2rem;
        line-height: 3rem;
    }
}

p {
    font-size: 1.2rem;
    color: $space-grey;
}

ul, ol {
    font-size: 1.2rem;
}

a, img { transition: .2s ease-in-out; }
