//.social-sharing
//{
//    list-style-type: none;
//    padding: 0;
//    border: 0;
//    margin: 15px 0;
//
//    &__item
//    {
//        display: inline-block;
//        border: 1px solid $primary-color;
//
//        a {
//            padding: 10px;
//            display: block;
//        }
//    }
//}


// variables
$transition: all 0.35s cubic-bezier(0.310, -0.105, 0.430, 1.590);
$size: 40px;
$colors: (
        "facebook": "#3B5998",
        "twitter": "#3CF",
        "google": "#DC4A38",
        "linkedin": "#0077B5"
);

.social-buttons {
    height: $size;
    margin: auto;
    font-size: 0;
    text-align: left;

    .social-button {
        &:first-child {
            margin-left: 0;
        }
    }

    @media #{$medium-up} {
        text-align: right;

        .social-button {
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.social-button {
    display: inline-block;
    background-color: $space-grey;
    width: $size;
    height: $size;
    line-height: $size;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    //overflow bug fix
    opacity: .99;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.05);
    transition: $transition;

    &:before {
        content: '';
        background-color: #000;
        width: 140%;
        height: 140%;
        position: absolute;
        top: 80%;
        left: -120%;
        transform: rotate(45deg);
        transition: $transition;
    }

    .icon {
        font-size: $size / 2.2;
        vertical-align: middle;
        transform: scale(.8);
        transition: $transition;
        position: relative;
        color: $white;
    }

    @each $class,$color in $colors {

        &.#{$class} {

            &:before {
                background-color: #{$color};
            }

            .icon {
                color: #{$white};
            }
        }
    }

    &:focus,
    &:hover {

        &:before {
            top: -20%;
            left: -20%;
        }

        .icon {
            color: #fff;
            transform: scale(1);
        }
    }
}

/* Social Media Buttons on People Custom Posts */

.single__person-social {
    .button {
        border: solid 1px;
        transition: all 0.25s linear;
        &.button-twitter {
            background-color: $twitter;
            border-color: $twitter;
            &:hover {
                background-color: $white;
                color: $twitter;
            }
        }

        &.button-facebook {
            background-color: $facebook;
            border-color: $facebook;
            &:hover {
                background-color: $white;
                color: $facebook;
            }
        }
        &.button-linkedin {
            background-color: $linkedin;
            border-color: $linkedin;
            &:hover {
                background-color: $white;
                color: $linkedin;
            }
        }
        .button-email {
            background-color: $black;
            border-color: $black;
            &:hover {
                background-color: $white;
                color: $black;
            }
        }
    }
}

.social--sharing {
    margin-top: 30px;
}